<template>
	<!--发起入驻-->
	<mainBox mClass='fqBox'>
		<div class="addHtml">
			<div class="ySteps">
				<div v-for='(it,dex) in stepArrs' :key='dex' class="it_step" :class="{isPass:nowSteps>=it.val}">
					<div v-if="dex>0" class="line"></div>
					<div class="round">{{it.val}}</div>{{it.name}}
				</div>
			</div>
			<div class="infoFrom" ref="infoFrom">
				<TForm class='basisBox' :autoCheck='true' v-if='nowSteps<2' ref="htmlFrom" :model="pageParams"  :formlist="formlist" label-width="158px" labelPosition='left'>
					<template slot="oTypeArrs" slot-scope="scope">
						<!--pageParams[scope.current.keys]-->
						<el-cascader  v-model="pageParams[scope.current.keys]"  :options="positionArrs" :disabled="!pageParams.projectId" 
							 filterable clearable placeholder="请选择" :props="scope.current.checkProps"></el-cascader>
						<!--<el-select v-model="pageParams[scope.current.keys]" :disabled="!pageParams.projectId" filterable clearable placeholder="请选择">
	              <el-option v-for="(item,dex2) in positionArrs" :key="dex2" :label="item.positionName" :value="item.positionId"></el-option>
	          </el-select>-->
						<span class="soltSpan">租赁单元未找到位置可点击右侧添加：</span>
						<el-button size='small' @click='toAddPosition'>添加新位置</el-button>
						
					</template>
				</TForm>
				<div class="costWarp" v-else>
					<div class="border1px">
						<TForm  ref="htmlFrom2" :autoCheck='true' :model="costFrom"  :formlist="formlist2" label-width="120px" labelPosition='left'>
							<template slot="priceInput" slot-scope="scope">
								<el-input v-model="costFrom[scope.current.keys]" v-input-number  placeholder="请输入" >
									<template slot="append">元</template>
							 	</el-input>
							</template>
							
							
						</TForm>
						<div class="part_cost">租赁相关费用合计：{{totalCost1}}元</div>
					</div>
					<div class="border1px">
						<TForm  ref="htmlFrom3"  :autoCheck='true' :model="costFrom2"  :formlist="formlist3" label-width="120px" labelPosition='left'>
							<template slot="priceInput" slot-scope="scope">
								<el-input v-model="costFrom2[scope.current.keys]" v-input-number  placeholder="请输入" >
									<template slot="append">元</template>
							 	</el-input>
							</template>
						</TForm>
						<div class="part_cost">物业相关费用合计：{{totalCost2}}元</div>
					</div>
					<div class="allCost">
						入驻首笔费用 合计：<span>{{getTotalNums()}}</span>元
					</div>
				</div>
			</div>
			<div class="f_btns" v-if="nowSteps<2">
					<el-button type="primary" class='w130' @click='nextStep'>下一步</el-button>
				</div>
				<div class="f_btns tx_right" v-else>
					<el-button type="primary" class='w130' @click='nowSteps--'>上一步</el-button>
					<el-button type="primary" class='w130' @click='onSubmit'>保存提交</el-button>
				</div>
		</div>
		<AddPosition v-if='posiInfo.open' :isOpen='posiInfo.open' @close='closeAddPosition' :projectId='pageParams.projectId' :optionArr='positionArrs' />
		<NotifyPerson v-if='notifyInfo.open' :isOpen='notifyInfo.open' :datas='notifyInfo.datas' @close='closeNotifyModel'  />
	</mainBox>
</template>

<script>
	import TForm from '@/components/YTable/TForm.vue';
	import AddPosition from './AddPosition.vue'
	import NotifyPerson from './NotifyPerson.vue'
	import pinyin from "js-pinyin";
	export default {
		computed: {},
		components: {TForm,AddPosition,NotifyPerson},
		props: {},
		watch: {
			costFrom: {
				deep: true, // 深度监听
				handler(val, oval) {
					setTimeout(()=>{
						let nums=0;
						for(let keys in val){
							let _n=Number(val[keys]);
							if(+_n>-1){
								nums+=_n
							}else{
								val[keys]=0;
								isAllow=false;
								break;
							}
						}
						this.totalCost1=nums.toFixed(2);
//						console.log('val',JSON.parse(JSON.stringify(val)))
					},100)
				}
			},
			costFrom2: {
				deep: true, // 深度监听
				handler(val, oval) {
					let nums=0;
					for(let keys in val){
						let _n=Number(val[keys]);
						if(+_n>-1){
							nums+=_n
						}else{
							val[keys]=0;
							break;
						}
					}
//					console.log('costFrom2',JSON.parse(JSON.stringify(val)))
				this.totalCost2=nums.toFixed(2);
				}
			},
		},
		data() {
			return {
				nowSteps: 1,
				stepArrs: [
					{name: '客户基础信息',val: 1},
					{name: '入驻首笔费用',val: 2}
				],
				pageParams: {}, //基础配置参数
				costFrom: {}, //费用参数
				costFrom2: {}, //费用参数
				totalCost1:0,
				totalCost2:0,
				positionArrs:[],
				posiInfo: { //新增位置弹框信息
					open: false,
					dataId: '',
				},
				notifyInfo: { //选择联系人
					open: false,
					dataId: '',
					datas:{},
				},
				formlist: [
					{name: '所属项目',keys: 'projectId',value: '',type: 'select',isMust:true,options:[],changMethod:this.changeProject},
					{name: '企业全称',keys: 'enterpriseName',value: '',type: 'input',isMust:true},
					{name: '企业所属行业类型',keys: 'enterpriseIndustryType',value: '',type: 'input',isMust:true},
					{name: '联系人姓名',keys: 'liaison',value: '',type: 'input',isMust:true},
					{name: '联系人电话',keys: 'liaisonPhone',value: '',type: 'input',isMust:true},
					{name: '联系人职位',keys: 'liaisonPosition',value: '',type: 'input',isMust:true},
					{name: '租赁单元',keys: 'positionId',value: '',type: 'slot',slotName: 'oTypeArrs',isMust:true,
					checkProps:{ value:'positionId',label:'positionName',children:'children',checkStrictly:true},},
					{name: '是否租用空调',keys: 'rentAircondition',value: 'N',type: 'checkD',isMust:true,options:[
						{name:'是',code:'Y'},
						{name:'否',code:'N'}
					]},
					{name: '是否租用宿舍',keys: 'rentDormitory',value: 'N',type: 'checkD',isMust:true,options:[
						{name:'是',code:'Y'},
						{name:'否',code:'N'}
					]},
					{name: '入驻日期',keys: 'rentDate',value: '',type: 'time',isMust:true,format:'timestamp'},
					{name: '租赁合同起止日期',keys: 'leaseContractTime',value: '',type: 'stEndTime',isMust:true},
					{name: '租赁期限（月）',keys: 'rentTerm',value: '',type: 'input',isMust:true,isNum:true},
					{name: '物业合同编号',keys: 'propertyContractCode',value: '',type: 'input',isMust:true},
					{name: '租赁合同编号',keys: 'rentContractCode',value: '',type: 'input',isMust:true},
					{name: '计租面积（m2）',keys: 'rentArea',value: '',type: 'input',isMust:true},
				],
				formlist2: [
					{name: '月租金',keys: 'monthlyPrice',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '租赁押金',keys: 'rentDeposit',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '空调押金',keys: 'airconditionDeposit',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '空调费',keys: 'airconditionPrice',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
				],
				formlist3: [
					{name: '首月管理费',keys: 'firstManagementExpenses',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '履约保证金',keys: 'performanceEarnestMoney',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '专项维修资金',keys: 'specialMaintenanceFund',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '燃气开户费',keys: 'gasAccountOpeningFee',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
					{name: '燃气使用保证金',keys: 'gasUsageDeposit',value: '',type: 'slot',slotName: 'priceInput',flex:8,isMust:true},
				],
				orderId:'',
			};
		},
		created() {
			const {orderId}=this.$route.query;
			if(orderId) this.orderId=orderId;
			this.initNowHtml();
			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
		},
		mounted() {},
		methods: {
			getTotalNums(){
				return (+this.totalCost1)+(+this.totalCost2)
			},
			async initNowHtml() {
				this.nowSteps = 1;
				this.resetFormItVals();
				this.initProjectArrs();
				this.getDetailById();
			},
			async	getDetailById(){
      	let params={
      		rentOrderId:this.orderId,
      		isloading:false,
      	}
      	if(!params.rentOrderId) return;
	     	let bkData = await this.ApiHttp('/organization/enterprise/rent/selectDetail',params);
	     	this.detailLoading = false;
      	if(bkData){
      		let res=bkData.enterpriseRentOrderVO;
      		res.rentDate=new Date(res.rentDate).getTime();
      		res.leaseContractTime=[res.leaseContractStartDate,res.leaseContractEndDate];
      		this.pageParams = {...res};
      		
      		for(let it of this.formlist2){
      			this.costFrom[it.keys]=res[it.keys];
      		}
      		for(let it of this.formlist3){
      			this.costFrom2[it.keys]=res[it.keys];
      		}
      		await this.initPositionTree(res.projectId);
      		this.pageParams.positionId=this.common.findAllParentsByNode(res.positionId,this.positionArrs,'positionId');
      	}
			},
			async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.name = it.projectName;
						it.code = it.projectId;
					})
					this.common.insertOptions(this.formlist,'projectId',res);
				}
				
			},
			changeProject(val,it){
				console.log(val,it)
				//项目切换
				this.pageParams.positionId='';
				this.initPositionTree(this.pageParams.projectId);
			},
			async initPositionTree(projectId){
				let res=await this.ApiHttp('/base/tenant/position/findPositionTree?projectId='+projectId);
				if(res){
//					res.map(it=>{
//						it.name = it.positionName;
//						it.code = it.positionId;
//					})
					this.positionArrs = this.common.listToTree(res, "positionId", "parentId");
					console.log('this.positionArrs',JSON.parse(JSON.stringify(this.positionArrs)))
//					this.positionArrs=res;
//					this.common.insertOptions(this.formlist,'positionId',res);//添加选项数据到所属项目
				}
			},
			toAddPosition() {
				this.posiInfo={ //新增位置弹框信息
					open: true,
					dataId: '',
				};
			},
			toEditPosition(row) {},
			closeNotifyModel(isInit) {
				this.notifyInfo.open=false;
				if(isInit){
					this.$router.go(-1)
				}
			},
			closeAddPosition(isInit) {
				this.posiInfo.open=false;
				if(isInit){
					this.initPositionTree(this.pageParams.projectId);
				}
			},
			resetFormItVals() {
				//重置表单的值  父组件有调用
				for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.pageParams, item.keys, item.value);
				}
				for(let item of this.formlist2) { //初始化赋值我的表单数据
					this.$set(this.costFrom, item.keys, item.value);
					if(item.auditkey) this.$set(this.costFrom, item.auditkey, item.auditVal);
				}
				for(let item of this.formlist3) { //初始化赋值我的表单数据
					this.$set(this.costFrom2, item.keys, item.value);
					if(item.auditkey) this.$set(this.costFrom2, item.auditkey, item.auditVal);
				}
			},
			async valiCostFrom1(){
				//校验相关费用是否为空
				let allow=true;
				this.$refs.htmlFrom2.validate(res => allow=res);
				if(allow){
					this.$refs.htmlFrom3.validate(res => allow=res);
				}
				return allow
				console.log('allow',allow)
			},
			async onSubmit() {
				let isSub=await this.valiCostFrom1();
				if(!isSub) return;
				console.log('onSubmit')
				let params = {
					...this.pageParams,
					...this.costFrom,
					...this.costFrom2,
					enterpriseCode:pinyin.getCamelChars(this.pageParams.enterpriseName),
				}
				const {leaseContractTime}=params
				if(leaseContractTime.length>0){
					params.leaseContractStartDate=new Date(leaseContractTime[0]).getTime();
					params.leaseContractEndDate=new Date(leaseContractTime[1]).getTime();
					delete params.leaseContractTime;
				}
				this.notifyInfo.datas=JSON.parse(JSON.stringify(params));
				this.notifyInfo.open=true;
				console.log('89898',JSON.parse(JSON.stringify(params)))
//				let res = await this.ApiHttp('/order/tenant/orderConfig/' + urlType, params, sendType)
			},
			nextStep() {
				this.$refs.htmlFrom.validate(res => {
					if(!res) {
						this.$message({
							message: '请检查输入是否正确',
							type: 'warning'
						})
						return;
					}
					this.nowSteps++;
				})

			},
			
		},
		destroyed() {},
	};
</script>

<style lang="scss" scoped>
	.addHtml{
		height: 100%;
		.infoFrom{
			height: calc(100% - 120px);
			overflow-y: auto;
			.border1px{
				margin-bottom: 32px;
				padding: 24px;
				padding-bottom: 4px;
				border: 1px solid #DCDFE5;
				position: relative;
			}
			.part_cost{
				text-align: right;
				position: absolute;
				right: 24px;
				bottom: 36px;
			}
			.allCost{
				color: #000000;
				font-weight: bold;
				font-size: 16px;
			}
		}
	}
	
	.ySteps {
			padding: 24px 0;
			text-align: center;
			margin: 0 auto;
			.it_step {
				color: #DBDFEA;
				position: relative;
				display: inline-block;
				font-size: 18px;
				font-weight: bold;
				.round {
					position: relative;
					top: -1px;
					margin-right: 10px;
					display: inline-block;
					width: 24px;
					height: 24px;
					border-radius: 50%;
					color: white;
					text-align: center;
					line-height: 24px;
					font-size: 16px;
					background: #DBDFEA;
				}
				.line {
					display: inline-block;
					width: 80px;
					height: 2px;
					background: #DBDFEA;
					vertical-align: middle;
					position: relative;
					margin: 0 10px;
					top: -2px;
				}
			}
			.it_step.isPass {
				color: #2a61ff;
				.round,
				.line {
					background: #2a61ff;
				}
			}
		}
</style>
<style lang="scss">
	.fqBox.mainBox .m-body{
		padding: 24px !important;
	}
	.basisBox{
		.el-input,.el-select{
			width: 350px !important;
		}
		.soltSpan{
			display: inline-block;
			padding-left: 12px;
			width: 150px;
	    line-height: 16px;
	    vertical-align: bottom;
	    color: #303133;
		}
	}
	.costWarp{
		.el-input{
			width: 88% !important;
		}
		
	}
</style>