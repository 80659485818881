<template>
	<div class="Y-tips">
		<el-dialog class='diyModel' title="添加新位置" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="30%" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'></TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			dataId: {
				type: String|Number,
				default: '',
			},
			projectId: {
				type: String|Number,
				default: '',
			},
			optionArr:{
				type: Array,
				default: [],
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{
					projectId:'',
					tagList:[],
  			},
  			isHttping:false,
  			formlist:[
  				{name: '上级位置',keys: 'parentIdArr',value: [],type: 'cascader',isMust:true,options:[],checkProps:{ value:'positionId',label:'positionName',children:'children',checkStrictly:true}},
  				{name: '位置名称',keys: 'positionName',value: '',type: 'input',isMust:true,trigger: ["blur", "change"]},
  			],
			}
		},
		
		computed: {},
		watch:{
			dataId: {
				deep: true, // 深度监听
				handler(val, oval) {
					this.getDatasById();
					this.$nextTick(() => {
						this.$refs.htmlFrom.resetFields();
					})
				}
			},
			
		},
		created() {
			this.resetTFrom();
			this.getDatasById();
		},
		mounted() {
			this.$nextTick(()=>{
				this.common.insertOptions(this.formlist,'parentIdArr',this.optionArr);
				console.log('++++++++this.optionArr',this.optionArr)
			})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
		    changeCompanyName(val) {
		    	// 联动企业名称  生成编码
		      let n = pinyin.getCamelChars(val);
		      this.htmlDatas.materielCategoryCode=n;
		    },
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
	  		},
			async getDatasById(){
				if(this.dataId){
	  			let res = await this.ApiHttp('/base/tenant/materielcategory/find/'+this.dataId);
					if(res){
						this.htmlDatas={...res};
					}
				}else{
					//没有ID 代表新增，重置输入框
					this.resetTFrom()	
				}
			},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
					
				})
				
//				
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
					projectId:this.projectId,
					description:'',
					location:'',
					parentId:this.htmlDatas.parentIdArr[this.htmlDatas.parentIdArr.length-1],
				}
				params.positionCode=pinyin.getCamelChars(params.positionName);
				let res = await this.ApiHttp('/base/tenant/position/create',params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>