import { render, staticRenderFns } from "./NotifyPerson.vue?vue&type=template&id=b026bad8&scoped=true"
import script from "./NotifyPerson.vue?vue&type=script&lang=js"
export * from "./NotifyPerson.vue?vue&type=script&lang=js"
import style0 from "./NotifyPerson.vue?vue&type=style&index=0&id=b026bad8&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b026bad8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b026bad8')) {
      api.createRecord('b026bad8', component.options)
    } else {
      api.reload('b026bad8', component.options)
    }
    module.hot.accept("./NotifyPerson.vue?vue&type=template&id=b026bad8&scoped=true", function () {
      api.rerender('b026bad8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/enterprise/entrance/model/NotifyPerson.vue"
export default component.exports